import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../../graphqlTypes';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PageTitle from '../../components/PageTitle';
import Banner from '../../components/Banner';
import { scale } from '../../styled';
import ButtonLink from '../../components/ButtonLink';
import Grid from '../../components/Grid';
import CenteredBox, { CenteredBoxSizes } from '../../components/CenteredBox';
import ServicesBlock, { ServiceBlockTypes } from '../../components/ServicesBlock';
import RequestQuoteBlock from '../../components/RequestQuoteBlock';
import ReassuranceBlock from '../../components/ReassuranceBlock';

const PackageDeliveryPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetPackageDeliveryPageQuery>(
    graphql`
      query GetPackageDeliveryPage {
        file(name: { eq: "service-package-delivery" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 864, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 500, layout: CONSTRAINED)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox size={CenteredBoxSizes.S}>
        <p style={{ marginBottom: scale(2) }}>
          Vous recherchez un <b>service de livraison à Nantes</b> pour le transport de votre colis ?
          Optez pour un mode de livraison rapide, écologique et économique !
        </p>
        <h2>Notre service de livraison à Nantes</h2>
        <p>
          Vous avez une marchandise à expédier dans Nantes dans les meilleurs délais ? Que vous
          soyez dans le centre-ville ou dans l’agglomération nantaise, choisissez un coursier pour
          le transport ponctuel de marchandises et colis !
        </p>
        <p>
          Nous procédons à l’enlèvement de vos envois directement dans vos locaux ou à une adresse
          convenue, puis au transport sécurisé jusqu’à la remise en main propre. C’est{' '}
          <b>aussi simple que cela !</b>
        </p>
        <p style={{ marginBottom: scale(2) }}>
          Un ou plusieurs colis, petits colis ou colis lourds, nos coursiers expérimentés
          transportent <b>jusqu’à 150 kg de marchandises</b> sur leur vélo cargo spécialement
          adapté. De quoi vous assurer un service de qualité, qui répond à tous vos besoins !
        </p>
        <Grid>
          <React.Fragment>
            <GatsbyImage
              image={pageData.image_1?.childImageSharp?.gatsbyImageData}
              alt=""
              style={{ maxWidth: '100%', borderRadius: scale(2) }}
            />
          </React.Fragment>
          <React.Fragment>
            <h2>Votre envoi de colis, moins cher</h2>
            <p>
              Votre entreprise dispose de plusieurs locaux à Nantes, ou vous échangez régulièrement
              avec les mêmes partenaires ?
            </p>
            <p>
              Optez pour <b>notre service “tournée”</b> : nous faisons la navette entre les
              différents établissements pour faire circuler vos documents et marchandises. Pas
              besoin d’affranchir vos colis : vous faites alors des économies !
            </p>
          </React.Fragment>
        </Grid>
        <h2>Notre équipe de livreurs s&apos;adapte à vos besoins</h2>
        <p>
          Avec Green Course, ne vous déplacez plus ! Nous effectuons votre course en vous
          garantissant réactivité et flexibilité.
        </p>
        <p style={{ textAlign: 'center', marginBottom: scale(2) }}>
          <ButtonLink to="/tarifs">Retrouvez nos tarifs</ButtonLink>
        </p>
        <GatsbyImage
          image={pageData.image_2?.childImageSharp?.gatsbyImageData}
          alt=""
          style={{ width: '100%', borderRadius: scale(2) }}
        />
      </CenteredBox>
      <ServicesBlock
        title="Nos autres services"
        data-testid="block-services"
        displayedBlockTypes={[ServiceBlockTypes.MailService, ServiceBlockTypes.UrgentTransport]}
      />
      <RequestQuoteBlock data-testid="block-request-quote" />
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
    </Layout>
  );
};

export default PackageDeliveryPage;
